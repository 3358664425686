import "./App.css";
import ShortContainer from "./components/ShortContainer";

function App() {
  return (
    <div>
      <nav className="navbar">
        <div className="nav">
          <div className="logo-container">
            <img src="/logo192.png" className="logo" alt="" />
          </div>

          <ul>
            <li>
              <ion-icon name="notifications-outline"></ion-icon>
            </li>
            <li>
              <img
                src="https://cdn.rajm.com.np/blob/ams_1673956912_9597%20-%20Copy.png"
                alt=""
              />
            </li>
          </ul>
        </div>
      </nav>
      <main className="main">
        
        <ShortContainer />
      </main>
    </div>
  );
}

export default App;
